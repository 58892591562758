import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useMemo,
} from "react";
import "leaflet/dist/leaflet.css";
import { Button, message, Table, Spin, Checkbox, Input } from "antd";
import "./CubaMap.css";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { CloseOutlined, SaveOutlined } from "@ant-design/icons";

export const Axios = axios.create({
  baseURL: process.env.REACT_APP_APPI_ADDRESS,
});

const CubaMap = ({ center, onCoordinatesSelect, data, onClose }) => {
  const { t } = useTranslation();
  const mapRef = useRef(null);
  const markerRef = useRef(null); // Usamos markerRef para almacenar el marcador
  const [point, setPoint] = useState(null);
  const [spinning, setSpinning] = useState(false);
  const [spinTitle, setSpinTitle] = useState(t("global.loading"));
  const [isMapLoading, setIsMapLoading] = useState(true);
  const [messageApi, contextHolder] = message.useMessage();
  const { TextArea } = Input;
  const [referencePoint, setReferencePoint] = useState(
    Number(data.referencepoint) || 0
  );
  const [observations, setObservations] = useState(data.observations ?? "");

  console.log(data);

  const headers = useMemo(
    () => ({
      accept: "*/*",
      Authorization: "Bearer " + sessionStorage.getItem("token"),
      "Content-Type": "application/json",
    }),
    []
  );

  const loadMap = useCallback(() => {
    const L = require("leaflet");

    if (mapRef.current === null) {
      const mapInstance = L.map("map", {
        center: center || [21.5, -77.0],
        zoom: 15, // Zoom inicial configurado
      });

      L.tileLayer("https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png", {
        maxZoom: 19,
      }).addTo(mapInstance);

      mapRef.current = mapInstance;
      setIsMapLoading(false);

      if (data.latitude && data.longitude) {
        const markerIcon = L.icon({
          iconUrl:
            "https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-icon.png",
          shadowUrl:
            "https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-shadow.png",
          iconSize: [25, 41],
          iconAnchor: [12, 41],
          shadowSize: [41, 41],
        });

        // Agregar marcador inicial en las coordenadas dadas por data
        markerRef.current = L.marker([data.latitude, data.longitude], {
          icon: markerIcon,
        }).addTo(mapInstance);
        setPoint({ lat: data.latitude, lng: data.longitude });

        // Centrar el mapa en las coordenadas usando flyTo
        mapInstance.flyTo([data.latitude, data.longitude], 15, {
          animate: true,
          duration: 1.5,
        });
      }
    }
  }, [center, data.latitude, data.longitude]);

  useEffect(() => {
    loadMap();

    return () => {
      if (mapRef.current) {
        mapRef.current.remove();
        mapRef.current = null;
      }
    };
  }, [loadMap]);

  useEffect(() => {
    if (mapRef.current && center) {
      mapRef.current.flyTo(center, 15, {
        animate: true,
        duration: 1.5,
      });
    }
  }, [center]);

  useEffect(() => {
    setReferencePoint(Number(data.referencepoint) || 0);
    setObservations(data.observations ?? "");
  }, [data]);

  // Manejar el evento de doble clic para seleccionar coordenadas
  useEffect(() => {
    if (mapRef.current) {
      mapRef.current.on("dblclick", (event) => {
        const { lat, lng } = event.latlng;
        const newPoint = {
          lat: parseFloat(lat.toFixed(6)),
          lng: parseFloat(lng.toFixed(6)),
        };

        const L = require("leaflet");
        const markerIcon = L.icon({
          iconUrl:
            "https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-icon.png",
          shadowUrl:
            "https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-shadow.png",
          iconSize: [25, 41],
          iconAnchor: [12, 41],
          shadowSize: [41, 41],
        });

        // Eliminar el marcador existente si existe
        if (markerRef.current) {
          markerRef.current.remove();
        }

        // Crear nuevo marcador y asignarlo a markerRef
        markerRef.current = L.marker([newPoint.lat, newPoint.lng], {
          icon: markerIcon,
        }).addTo(mapRef.current);
        setPoint(newPoint); // Actualizar el estado del punto seleccionado
        onCoordinatesSelect && onCoordinatesSelect(newPoint); // Llamar a la función de callback con la nueva coordenada
      });
    }
  }, [onCoordinatesSelect]);

  const handleSendCoordinates = useCallback(() => {
    setSpinning(true);
    setSpinTitle(t("global.processing_data"));
    Axios.post(
      "sd/updateInvoiceCoordinates",
      {
        idinvoice: data.idinvoice,
        latitude: point?.lat,
        longitude: point?.lng,
        referencepoint: referencePoint,
        observations,
      },
      { headers }
    )
      .then((response) => {
        messageApi.open({
          type: response.data.success ? "success" : "error",
          content: response.data.message,
          duration: 2.5,
        });
        setSpinning(false);
        if (response.data.success && onClose) onClose();
      })
      .catch(() => {
        setSpinning(false);
        messageApi.open({
          type: "error",
          content: t("map.error_saving"),
          duration: 2.5,
        });
      });
  }, [
    data.idinvoice,
    headers,
    messageApi,
    onClose,
    point,
    t,
    referencePoint,
    observations,
  ]);

  const handleClearCoordinates = useCallback(() => {
    setPoint(null);
    if (markerRef.current) {
      markerRef.current.remove(); // Eliminar el marcador actual
      markerRef.current = null;
    }
  }, []);

  const columns = [
    { title: t("map.latitude"), dataIndex: "lat", key: "lat" },
    { title: t("map.longitude"), dataIndex: "lng", key: "lng" },
  ];

  return (
    <Spin spinning={spinning} tip={spinTitle}>
      <div style={{ display: "flex" }}>
        <div id="map" style={{ height: "100vh", width: "70%" }}>
          {isMapLoading && <Spin tip={t("global.loading_map")} />}
        </div>
        <div
          style={{
            padding: "20px",
            width: "30%",
            overflow: "auto",
            maxHeight: "100vh",
          }}
        >
          <p
            style={{
              textAlign: "center",
              margin: "10px 0",
              fontSize: "24px",
              fontWeight: "bold",
              color: "#2c3e50",
              fontFamily: "'Roboto', sans-serif",
              borderBottom: "2px solid #61768B",
              paddingBottom: "5px",
              textShadow: "1px 1px 2px rgba(0,0,0,0.2)",
            }}
          >
            {t("map.coordinates")}
          </p>
          <Table
            dataSource={
              point ? [{ key: 0, lat: point.lat, lng: point.lng }] : []
            }
            columns={columns}
            pagination={false}
            bordered
            scroll={{ y: 400 }}
            style={{ boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)" }}
          />
          <div style={{ marginTop: "20px" }}>
            <Checkbox
              style={{ marginBottom: "10px" }}
              checked={referencePoint === 1}
              onChange={(e) => setReferencePoint(e.target.checked ? 1 : 0)}
            >
              {t("map.reference_point")}
            </Checkbox>

            <TextArea
              rows={4}
              placeholder={t("map.observations")}
              style={{ width: "100%", resize: "none" }}
              value={observations}
              onChange={(e) => setObservations(e.target.value)}
            />
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "20px",
            }}
          >
            <Button
              icon={<CloseOutlined />}
              type="default"
              danger
              onClick={handleClearCoordinates}
              style={{ marginRight: "10px" }}
            >
              {t("map.clear_coordinates")}
            </Button>
            <Button
              icon={<SaveOutlined />}
              type="primary"
              onClick={handleSendCoordinates}
            >
              {t("map.save_coordinates")}
            </Button>
          </div>
        </div>
      </div>
    </Spin>
  );
};

export default CubaMap;
