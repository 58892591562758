import React, { useEffect, useState } from "react";
import axios from "axios";
import {
  Table,
  Input,
  Button,
  Form,
  DatePicker,
  Modal,
  Space,
  Tooltip,
  Spin,
  message,
  Row,
  Col,
} from "antd";
import moment from "moment";
import { FormattedMessage } from "react-intl";
// import {SearchOutlined} from "react-icons/io5";
import {
  CloseOutlined,
  ImportOutlined,
  SearchOutlined,
  CloudDownloadOutlined,
  EnvironmentOutlined,
} from "@ant-design/icons";
import {
  IoCheckmarkCircleSharp,
  IoReload,
  IoLocation,
  IoLocationOutline,
  IoLocationSharp,
} from "react-icons/io5";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import StatusFacturas from "./StatusFacturas";
import SelectCoordinatesModal from "./SelectCoordinatesModal";
import AdvancedInvoiceSearchModal from "./AdvancedInvoiceSearchModal";
import UpdateInvoiceCoordinatesModal from "./UpdateInvoiceCoordinatesModal";
import InvoiceDetailsModal from "./InvoiceDetailsModal";

const { RangePicker } = DatePicker;

export const Axios = axios.create({
  baseURL: process.env.REACT_APP_APPI_ADDRESS,
});

function Facturas() {
  const { t, i18n } = useTranslation();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [form] = Form.useForm();
  const [formBuscarFactura] = Form.useForm();
  const [isModalOpen, setIsModalOpen] = useState(false); // Estado para controlar el modal
  const [isModalBuscarFacturaOpen, setIsModalBuscarFacturaOpen] =
    useState(false); // Estado para controlar el modal
  const [spinning, setSpinning] = useState(false);
  const [spinTitle, setSpinTitle] = useState(t("global.loading"));
  const [bottom, setBottom] = useState("bottomCenter");
  const [messageApi, contextHolder] = message.useMessage();
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const [isModalOpenDetalles, setIsModalOpenDetalles] = useState(false); // Estado para controlar el modal
  const [selectedInvoice, setSelectedInvoice] = useState(null); // Almacenar la factura seleccionada

  const [isModalVisible, setIsModalVisible] = useState(false);

  const [isModalManualCoordVisible, setIsModalManualCoordVisible] =
    useState(false);
  const [formManualCoordinates] = Form.useForm();

  const [dataRecord, setDataRecord] = useState([]);

  const showModalSetCoordinates = () => setIsModalVisible(true);
  const showModalManualSetCoordinates = () =>
    setIsModalManualCoordVisible(true);

  const headers = {
    accept: "*/*",
    Authorization: "Bearer " + sessionStorage.getItem("token"),
    "Content-Type": "application/json",
  };

  useEffect(() => {
    recargar();
  }, []);

  const fetchShippingData = async (values) => {
    const { enterprise, dates, status } = values;

    let inidate = null;
    let enddate = null;

    if (dates && dates.length === 2) {
      // Verifica que dates tenga exactamente dos fechas
      const [startDate, finishDate] = dates;
      if (startDate && finishDate) {
        inidate = startDate.format("YYYY-MM-DD");
        enddate = finishDate.format("YYYY-MM-DD");
      }
    }

    // console.log('Inidate:', inidate); // Para depuración
    // console.log('Enddate:', enddate); // Para depuración

    console.log(
      "REACT_APP_SOLVED_API_TEST:",
      process.env.REACT_APP_SOLVED_API_TEST
    );
    console.log(
      "REACT_APP_SOLVED_API_REAL:",
      process.env.REACT_APP_SOLVED_API_REAL
    );

    // const url = "https://www.solved1.com/api/appi/playa-dev/shippingdata/";
    const url = process.env.REACT_APP_SOLVED_API_REAL;
    const params = {
      apikey: "6I77Z9V01XV7IE3U5BM7XQ4X3D3PNK",
      data: {
        enterprise: enterprise,
        inidate: inidate,
        enddate: enddate,
        status: status || "",
      },
    };

    setError(null);

    try {
      setSpinTitle(t("global.loading"));
      setSpinning(true);
      const response = await axios.get(url, {
        params: { apikey: params.apikey, data: JSON.stringify(params.data) },
      });
      const rawData = response.data;

      if (!rawData || typeof rawData !== "object") {
        throw new Error("Datos inválidos recibidos");
      }

      const formattedData = Object.keys(rawData).map((code) => ({
        code,
        ...rawData[code],
      }));
      // console.log(formattedData);

      const invoiceData = [];

      // Recorrer el resultado de la API
      formattedData.forEach((invoice) => {
        const formattedInvoice = {
          code: invoice.code,
          rdatereserve: invoice.rdatereserve,
          iddeliverstate: invoice.iddeliverstate, //id del estado
          deliverstate: invoice.deliverstate, // denominacion de estado
          // cname: invoice.cname,
          // cidentity: invoice.cidentity,
          // address: invoice.address,
          // cprovince: invoice.cprovince,
          // cmunicipality: invoice.cmunicipality,
          // sname: invoice.sname,
          consignee: invoice.consignee, // destinatario
          shipper: invoice.shipper, // remitente
          products: invoice.products, // Asumiendo que los productos ya tienen el formato correcto
        };

        // Agregar la factura formateada al array invoiceData
        invoiceData.push(formattedInvoice);
      });

      // console.log({invoiceData});
      let message;

      Axios.post("/sd/addInvoiceProducts", invoiceData, {
        headers: headers,
      })
        .then((response) => {
          // console.log(response.data.message);
          setSpinning(false);
          message = response.data.message;
          if (response.data.success) {
            messageApi.open({
              type: "success",
              content: message,
              duration: 0,
            });

            setTimeout(messageApi.destroy, 2500);
            recargar();
          } else {
            messageApi.open({
              type: "error",
              content: message,
              duration: 0,
            });

            setTimeout(messageApi.destroy, 2500);
          }
        })
        .catch((error) => {
          setSpinning(false);
          messageApi.open({
            type: "error",
            content: message,
            duration: 0,
          });

          setTimeout(messageApi.destroy, 2500);
        });

      // setData(formattedData);
    } catch (error) {
      setError("Error fetching data");
    } finally {
      //setLoading(false);
      setSpinning(false);
      recargar();
    }
  };

  const recargar = () => {
    setSpinTitle(t("global.loading"));
    setSpinning(true);
    // setSelectedRowKeys([]);
    let aux = [];

    // let start = 0;
    // let limit = 1000;

    Axios.post(
      "sd/getFacturas",
      {
        // "start": start,
        // "limit": limit,
        // "filtro": {
        //     "idrol": 1
        // }
      },
      {
        headers: headers,
      }
    )
      .then((response) => {
        setSpinning(false);

        // Asumiendo que response.data.data es un array
        const rawData = response.data.data;

        // No necesitas usar Object.keys, solo asigna rawData directamente
        const formattedData = rawData.map((item) => ({
          code: item.code,
          idinvoice: item.idinvoice,
          rdatereserve: item.rdatereserve,
          cname: item.cname,
          consigneephone: item.consigneephone,
          cidentity: item.cidentity,
          address: item.address,
          cprovince: item.cprovince,
          cmunicipality: item.cmunicipality,
          sname: item.sname,
          shipperphone: item.shipperphone,
          idestadoinvoice: item.idestadoinvoice,
          estadoinvoice: item.estadoinvoice,
          prioridad: item.prioridad,
          latitude: item.latitude,
          longitude: item.longitude,
          referencepoint: item.referencepoint,
          observations: item.observations,
          coordinates: item.coordinates,
          products: JSON.parse(item.products), // Asegúrate de que el valor sea un array
        }));

        // console.log(formattedData);
        setData(formattedData);
      })
      .catch((error) => {
        setSpinning(false);
      });
  };
  const filterData = (data) => (formatter) =>
    data.map((item) => ({
      text: formatter(item),
      value: formatter(item),
    }));

  const showModalVerDetalles = (record) => {
    setSelectedInvoice(record); // Guardar la factura seleccionada
    setIsModalOpenDetalles(true); // Mostrar el modal
  };

  const handleCancelVerDetalles = () => {
    setIsModalOpenDetalles(false); // Cerrar el modal
    setSelectedInvoice(null); // Limpiar la factura seleccionada
  };

  const columns = [
    {
      // title: t('invoice.col_coordinates'),
      title: <IoLocation size={20} color={"#06af14"} />,
      dataIndex: "coordinates",
      key: "coordinates",
      // render: (text) => (text === 1) ? <IoLocation   size={20} color={'#06af14'}/> :
      //     <IoLocation   size={20} color={'red'}/>,
      render: (text, record) => (
        <div
          onClick={() => {
            // Establece la ubicación usando record.latitude y record.longitude
            setDataRecord(record);
            // Aquí puedes agregar cualquier otra lógica adicional, como abrir un modal
            setIsModalVisible(true);
          }}
          style={{ cursor: "pointer" }}
        >
          {text === 1 ? (
            <IoLocation size={20} color={"#06af14"} />
          ) : (
            <IoLocation size={20} color={"#adb5bd"} />
          )}
        </div>
      ),
      filterSearch: true,
      width: 150,
      filters: [
        { text: "SI", value: 1 },
        { text: "NO", value: 0 },
      ],
      // filters: _.uniqBy(data.map(item => ({ text: item.coordinates, value: item.coordinates })), 'value'),
      onFilter: (value, record) => record.coordinates === value,
      sorter: (a, b) => a.coordinates - b.coordinates,
    },
    {
      title: (
        <p
          style={{
            margin: "0px 0",
            fontSize: "15px",
            fontWeight: "bold",
            color: "#2c3e50",
            fontFamily: "'Roboto', sans-serif",
            textShadow: "1px 1px 2px rgba(0,0,0,0.2)",
          }}
        >
          {t("invoice.col_identificador")}
        </p>
      ),
      dataIndex: "code",
      key: "code",
      width: 80,
      filterSearch: true,
      filters: _.uniqWith(
        filterData(data)((i) => i.code),
        _.isEqual
      ),
      onFilter: (value, record) => record.code.startsWith(value),
      sorter: (a, b) => a.code.localeCompare(b.code),
      render: (text, record) => (
        <Button type="link" onClick={() => showModalVerDetalles(record)}>
          {text}
        </Button>
      ),
    },
    {
      title: (
        <p
          style={{
            margin: "0px 0",
            fontSize: "15px",
            fontWeight: "bold",
            color: "#2c3e50",
            fontFamily: "'Roboto', sans-serif",
            textShadow: "1px 1px 2px rgba(0,0,0,0.2)",
          }}
        >
          {t("invoice.col_fecha_reserva")}
        </p>
      ),
      dataIndex: "rdatereserve",
      key: "rdatereserve",
      width: 150,
      filterSearch: true,
      filters: _.uniqWith(
        filterData(data)((i) => i.rdatereserve),
        _.isEqual
      ),
      onFilter: (value, record) => record.rdatereserve.startsWith(value),
      sorter: (a, b) => a.rdatereserve.localeCompare(b.rdatereserve),
    },
    {
      title: (
        <p
          style={{
            margin: "0px 0",
            fontSize: "15px",
            fontWeight: "bold",
            color: "#2c3e50",
            fontFamily: "'Roboto', sans-serif",
            textShadow: "1px 1px 2px rgba(0,0,0,0.2)",
          }}
        >
          {t("invoice.col_destinatario")}
        </p>
      ),
      dataIndex: "cname",
      key: "cname",
      width: 300,
      filterSearch: true,
      filters: _.uniqWith(
        filterData(data)((i) => i.cname),
        _.isEqual
      ),
      onFilter: (value, record) => record.cname.startsWith(value),
      sorter: (a, b) => a.cname.localeCompare(b.cname),
    },
    /*{
      title: (
        <p
          style={{
            margin: "0px 0",
            fontSize: "15px",
            fontWeight: "bold",
            color: "#2c3e50",
            fontFamily: "'Roboto', sans-serif",
            textShadow: "1px 1px 2px rgba(0,0,0,0.2)",
          }}
        >
          {t("invoice.col_identidad")}
        </p>
      ),
      dataIndex: "cidentity",
      key: "cidentity",
      filterSearch: true,
      filters: _.uniqWith(
        filterData(data)((i) => i.cidentity),
        _.isEqual
      ),
      onFilter: (value, record) => record.cidentity.startsWith(value),
      sorter: (a, b) => a.cidentity.localeCompare(b.cidentity),
    },*/
    {
      title: (
        <p
          style={{
            margin: "0px 0",
            fontSize: "15px",
            fontWeight: "bold",
            color: "#2c3e50",
            fontFamily: "'Roboto', sans-serif",
            textShadow: "1px 1px 2px rgba(0,0,0,0.2)",
          }}
        >
          {t("invoice.col_direccion")}
        </p>
      ),
      dataIndex: "address",
      key: "address",
      filterSearch: true,
      filters: _.uniqWith(
        filterData(data)((i) => i.address),
        _.isEqual
      ),
      onFilter: (value, record) => record.address.startsWith(value),
      sorter: (a, b) => a.address.localeCompare(b.address),
    },
    {
      title: (
        <p
          style={{
            margin: "0px 0",
            fontSize: "15px",
            fontWeight: "bold",
            color: "#2c3e50",
            fontFamily: "'Roboto', sans-serif",
            textShadow: "1px 1px 2px rgba(0,0,0,0.2)",
          }}
        >
          {t("invoice.col_municipio")}
        </p>
      ),
      dataIndex: "cmunicipality",
      key: "cmunicipality",
      filterSearch: true,
      filters: _.uniqWith(
        filterData(data)((i) => i.cmunicipality),
        _.isEqual
      ),
      onFilter: (value, record) => record.cmunicipality.startsWith(value),
      sorter: (a, b) => a.cmunicipality.localeCompare(b.cmunicipality),
    },
    {
      title: (
        <p
          style={{
            margin: "0px 0",
            fontSize: "15px",
            fontWeight: "bold",
            color: "#2c3e50",
            fontFamily: "'Roboto', sans-serif",
            textShadow: "1px 1px 2px rgba(0,0,0,0.2)",
          }}
        >
          {t("invoice.col_provincia")}
        </p>
      ),
      dataIndex: "cprovince",
      key: "cprovince",
      filterSearch: true,
      filters: _.uniqWith(
        filterData(data)((i) => i.cprovince),
        _.isEqual
      ),
      onFilter: (value, record) => record.cprovince.startsWith(value),
      sorter: (a, b) => a.cprovince.localeCompare(b.cprovince),
    },
    {
      title: (
        <p
          style={{
            margin: "0px 0",
            fontSize: "15px",
            fontWeight: "bold",
            color: "#2c3e50",
            fontFamily: "'Roboto', sans-serif",
            textShadow: "1px 1px 2px rgba(0,0,0,0.2)",
          }}
        >
          {t("invoice.col_remitente")}
        </p>
      ),
      dataIndex: "sname",
      key: "sname",
      filterSearch: true,
      filters: _.uniqWith(
        filterData(data)((i) => i.sname),
        _.isEqual
      ),
      onFilter: (value, record) => record.sname.startsWith(value),
      sorter: (a, b) => a.sname.localeCompare(b.sname),
    },
    {
      title: (
        <p
          style={{
            margin: "0px 0",
            fontSize: "15px",
            fontWeight: "bold",
            color: "#2c3e50",
            fontFamily: "'Roboto', sans-serif",
            textShadow: "1px 1px 2px rgba(0,0,0,0.2)",
          }}
        >
          {t("invoice.col_stateinvoice")}
        </p>
      ),
      dataIndex: "estadoinvoice",
      key: "estadoinvoice",
      filterSearch: true,
      width: 150,
      filters: _.uniqWith(
        filterData(data)((i) => i.estadoinvoice),
        _.isEqual
      ),
      onFilter: (value, record) => record.estadoinvoice.startsWith(value),
      sorter: (a, b) => a.estadoinvoice.localeCompare(b.estadoinvoice),
    },
    /*{
      title: (
        <p
          style={{
            margin: "0px 0",
            fontSize: "15px",
            fontWeight: "bold",
            color: "#2c3e50",
            fontFamily: "'Roboto', sans-serif",
            textShadow: "1px 1px 2px rgba(0,0,0,0.2)",
          }}
        >
          {t("invoice.col_priority")}
        </p>
      ),
      dataIndex: "prioridad",
      key: "prioridad",
      filterSearch: true,
      width: 150,
      filters: _.uniqWith(
        filterData(data)((i) => i.prioridad),
        _.isEqual
      ),
      onFilter: (value, record) => record.prioridad.startsWith(value),
      sorter: (a, b) => String(a.prioridad).localeCompare(String(b.prioridad)),
    },*/
  ];

  const productColumns = [
    {
      title: t("invoice.col_nombre_producto"),
      dataIndex: "namegood",
      key: "namegood",
    },
    {
      title: t("invoice.col_cantidad"),
      dataIndex: "quantity",
      key: "quantity",
    },
    {
      title: t("invoice.col_peso"),
      dataIndex: "weight",
      key: "weight",
    },
    {
      title: t("invoice.col_longitud"),
      dataIndex: "length",
      key: "length",
    },
    {
      title: t("invoice.col_ancho"),
      dataIndex: "width",
      key: "width",
    },
    {
      title: t("invoice.col_alto"),
      dataIndex: "height",
      key: "height",
    },
    {
      title: t("invoice.col_dimensiones"),
      render: (text, record) =>
        `${record.length} x ${record.width} x ${record.height} (cm)`,
      key: "dimensions",
    },
  ];

  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 20,
    total: data.length, // Asegúrate de tener el total de elementos aquí
  });

  const handleTableChange = (newPagination) => {
    setPagination({
      ...pagination,
      current: newPagination.current,
      pageSize: newPagination.pageSize,
    });
  };

  const expandedRowRender = (record) => {
    return (
      <Table
        columns={productColumns}
        dataSource={record.products}
        pagination={false}
        rowKey="namegood"
      />
    );
  };

  const onFinish = (values) => {
    fetchShippingData(values);
    setIsModalOpen(false); // Cerrar el modal después de buscar
  };

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    form.resetFields();
    setIsModalOpen(false); // Cerrar el modal si se cancela
  };

  //
  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);

    if (newSelectedRowKeys.length === 1) {
      const selectedRow = data.find(
        (row) => row.code === newSelectedRowKeys[0]
      );
      // console.log("Selected Row: ", selectedRow);
      if (selectedRow) {
        // setDataRecord(selectedRow.cmunicipality + '. ' + selectedRow.cprovince);
        setDataRecord(selectedRow);
        // console.log(dataRecord);
      }
    }
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };
  const hasSelected = selectedRowKeys.length > 0;

  const handleDeselectAll = () => {
    setSelectedRowKeys([]); // Esto desmarca todas las filas
  };

  //Buscar facturas

  const busquedaAvanzada = async (values) => {
    setSpinTitle(t("global.loading"));
    setSpinning(true);

    // Validación para asignar valor null o vacío en caso de undefined o vacío
    const rdatereserve = values.rdatereserve
      ? values.rdatereserve.format("DD/MM/YYYY")
      : null;
    const codigo = values.codigo || "";
    const cname = values.cname || "";
    const sname = values.sname || "";

    Axios.post(
      "sd/getFacturas",
      {
        filtros: [
          {
            rdatereserve: rdatereserve,
            codigo: codigo,
            cname: cname,
            sname: sname,
          },
        ],
      },
      {
        headers: headers,
      }
    )
      .then((response) => {
        setSpinning(false);

        const rawData = response.data.data;

        // Formatear los datos
        const formattedData = rawData.map((item) => ({
          code: item.code,
          idinvoice: item.idinvoice,
          rdatereserve: item.rdatereserve,
          cname: item.cname,
          consigneephone: item.consigneephone,
          cidentity: item.cidentity,
          address: item.address,
          cprovince: item.cprovince,
          cmunicipality: item.cmunicipality,
          sname: item.sname,
          shipperphone: item.shipperphone,
          idestadoinvoice: item.idestadoinvoice,
          estadoinvoice: item.estadoinvoice,
          prioridad: item.prioridad,
          latitude: item.latitude,
          longitude: item.longitude,
          referencepoint: item.referencepoint,
          observations: item.observations,
          coordinates: item.coordinates,
          products: JSON.parse(item.products), // Asegúrate de que el valor sea un array
        }));

        setData(formattedData);
      })
      .catch((error) => {
        setSpinning(false);
      });
  };

  const onFinishBuscarFactura = (values) => {
    busquedaAvanzada(values);
    setIsModalBuscarFacturaOpen(false); // Cerrar el modal después de buscar
  };

  const showModalBuscarFactura = () => {
    setIsModalBuscarFacturaOpen(true);
  };

  const handleCancelBuscarFactura = () => {
    formBuscarFactura.resetFields();
    setIsModalBuscarFacturaOpen(false); // Cerrar el modal si se cancela
  };

  const handleClose = () => {
    // Lógica para cerrar el modal
    setIsModalVisible(false);
    recargar();
    handleDeselectAll();
  };

  const handleCancelManualCoordinates = () => {
    formManualCoordinates.resetFields();
    setIsModalManualCoordVisible(false); // Cerrar el modal si se cancela
  };

  const onFinishUpdateManualCoordinates = (values) => {
    setSpinning(true);
    setSpinTitle(t("global.processing_data"));
    Axios.post(
      "sd/updateInvoiceCoordinates",
      {
        idinvoice: dataRecord.idinvoice,
        latitude: values.latitude,
        longitude: values.longitude,
      },
      {
        headers: headers,
      }
    )
      .then((response) => {
        if (response.data.success) {
          messageApi.open({
            type: "success",
            content: response.data.message,
            duration: 0,
          });
          setTimeout(messageApi.destroy, 2500);

          handleCancelManualCoordinates();
          setSpinning(false);
          recargar();
          handleDeselectAll();
        } else {
          messageApi.open({
            type: "error",
            content: response.data.message,
            duration: 0,
          });

          setTimeout(messageApi.destroy, 2500);
        }
      })
      .catch((error) => {
        setSpinning(false);
        messageApi.open({
          type: "error",
          content: "Error al guardar el usuario",
          duration: 0,
        });

        setTimeout(messageApi.destroy, 2500);
      });
  };

  return (
    <Spin spinning={spinning} tip={spinTitle} size={"large"}>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          //alignItems: 'center',
          padding: "10px",
        }}
      >
        <p
          style={{
            textAlign: "center",
            margin: "10px 0",
            fontSize: "24px",
            fontWeight: "bold",
            color: "#2c3e50",
            fontFamily: "'Roboto', sans-serif",
            borderBottom: "2px solid #61768B",
            paddingBottom: "5px",
            textShadow: "1px 1px 2px rgba(0,0,0,0.2)",
          }}
        >
          {t("invoice.title")}
        </p>

        {contextHolder}

        <Space direction="horizontal">
          <Tooltip title={t("invoice.search")}>
            <Button
              style={{ marginRight: "0px", marginLeft: "10px" }}
              type="primary"
              shape="circle"
              className="button"
              icon={<CloudDownloadOutlined />}
              onClick={showModal}
            ></Button>
          </Tooltip>
          <Tooltip title={t("global.search")}>
            <Button
              style={{ marginRight: "0px", marginLeft: "0px" }}
              type="primary"
              shape="circle"
              className="button"
              icon={<SearchOutlined />}
              onClick={showModalBuscarFactura}
            ></Button>
          </Tooltip>
          <Tooltip title={t("global.coordinates")}>
            <Button
              style={{ marginRight: "0px", marginLeft: "0px" }}
              type="primary"
              shape="circle"
              className="button"
              icon={<EnvironmentOutlined />}
              onClick={showModalSetCoordinates}
              disabled={!hasSelected || loading || selectedRowKeys.length !== 1}
            ></Button>
          </Tooltip>
          <Tooltip title={t("global.manual_coordinates")}>
            <Button
              style={{ marginRight: "0px", marginLeft: "0px" }}
              type="primary"
              shape="circle"
              className="button"
              icon={<IoLocationSharp />}
              onClick={showModalManualSetCoordinates}
              disabled={!hasSelected || loading || selectedRowKeys.length !== 1}
            ></Button>
          </Tooltip>
          <Tooltip title={t("invoice.update")}>
            <Button
              style={{ marginRight: "0px", marginLeft: "0px" }}
              type="primary"
              shape="circle"
              className="button"
              icon={<IoReload />}
              onClick={recargar}
              loading={loading}
            ></Button>
          </Tooltip>
        </Space>

        {/*Modal para imortar las facturas*/}
        <Modal
          title={
            <div style={{ textAlign: "center", width: "100%" }}>
              {t("invoice.search_form")}
            </div>
          }
          open={isModalOpen}
          onCancel={handleCancel}
          footer={null}
        >
          <Form form={form} onFinish={onFinish} layout="vertical">
            <Form.Item
              name="enterprise"
              label={t("invoice.enterprise")}
              initialValue={"etcexpress"}
              rules={[
                { required: true, message: t("invoice.enterprise_required") },
              ]}
            >
              <Input placeholder={t("invoice.enterprise")} />
            </Form.Item>
            <Form.Item
              name="dates"
              label={t("invoice.dates_range")}
              rules={[
                { required: true, message: t("invoice.dates_range_required") },
              ]}
            >
              <RangePicker format="YYYY-MM-DD" style={{ width: "100%" }} />
            </Form.Item>
            <Form.Item name="status" label={t("invoice.status")}>
              {/*<Input placeholder={t('invoice.status_optional')}/>*/}
              <StatusFacturas placeholder={t("invoice.status_optional")} />
            </Form.Item>

            <Form.Item>
              <div style={{ textAlign: "center" }}>
                <Button
                  type="default"
                  icon={<CloseOutlined />}
                  danger
                  onClick={handleCancel}
                  style={{ marginRight: 8 }}
                >
                  {t("global.cancel")}
                </Button>
                <Button
                  type="primary"
                  icon={<ImportOutlined />}
                  htmlType="submit"
                  loading={loading}
                >
                  {t("global.import")}
                </Button>
              </div>
            </Form.Item>
          </Form>
        </Modal>

        {/*Modal para realizar busquedas de las facturas*/}
        <AdvancedInvoiceSearchModal
          isOpen={isModalBuscarFacturaOpen}
          onCancel={handleCancelBuscarFactura}
          onFinish={onFinishBuscarFactura}
          loading={loading}
          form={formBuscarFactura}
        />

        {/*Add manual coordinates*/}
        <UpdateInvoiceCoordinatesModal
          isOpen={isModalManualCoordVisible}
          onCancel={handleCancelManualCoordinates}
          onFinish={onFinishUpdateManualCoordinates}
          loading={loading}
          form={formManualCoordinates}
          data={dataRecord}
        />

        {/*mapa*/}
        <SelectCoordinatesModal
          isVisible={isModalVisible}
          onClose={handleClose}
          // onSelectCoordinates={handleCoordinatesSelect}
          data={dataRecord}
          // onClose={() => setIsModalVisible(false)}
        />

        {/*detalles de la factura*/}

        <InvoiceDetailsModal
          isOpen={isModalOpenDetalles}
          onCancel={handleCancelVerDetalles}
          selectedInvoice={selectedInvoice}
          productColumns={productColumns}
        />

        {error && <div>Error: {error}</div>}
        <Table
          dataSource={data}
          columns={columns}
          rowKey="code"
          bordered={true}
          expandable={{ expandedRowRender }}
          rowSelection={rowSelection}
          onChange={handleTableChange}
          pagination={{
            position: [bottom],
            current: pagination.current,
            pageSize: pagination.pageSize,
            showSizeChanger: true,
            pageSizeOptions: ["20", "40", "60", "80", "100"],
            // pageSizeOptions: ['10', '20', '50', '100'],
            showTotal: (total) => `Total ${total} elementos`, // Texto que muestra el total de elementos
          }}
        />
      </div>
    </Spin>
  );
}

export default Facturas;
