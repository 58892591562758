// InvoiceDetailsModal.js
import React from "react";
import { Modal, Table } from "antd";
import { useTranslation } from "react-i18next";

const InvoiceDetailsModal = ({
  isOpen,
  onCancel,
  selectedInvoice,
  productColumns,
}) => {
  const { t } = useTranslation();

  return (
    <Modal
      title={
        <div style={{ textAlign: "center", width: "100%" }}>
          {t("invoice.invoice_details")}
          {` ${selectedInvoice?.code}`}
        </div>
      }
      open={isOpen}
      onCancel={onCancel}
      footer={null}
      width={"50%"}
    >
      {selectedInvoice && (
        <div>
          <p>
            <strong>{t("invoice.col_fecha_reserva")}:</strong>{" "}
            {selectedInvoice.rdatereserve}
          </p>
          <p>
            <strong>{t("invoice.col_stateinvoice")}:</strong>{" "}
            {selectedInvoice.estadoinvoice}
          </p>
          <p>
            <strong>{t("invoice.col_priority")}:</strong>{" "}
            {selectedInvoice.prioridad}
          </p>
          <p>
            <strong>{t("invoice.col_destinatario")}:</strong>{" "}
            {selectedInvoice.cname}
          </p>
          <p>
            <strong>{t("invoice.col_consigneephone")}:</strong>{" "}
            {selectedInvoice.consigneephone}
          </p>
          <p>
            <strong>{t("invoice.col_identidad")}:</strong>{" "}
            {selectedInvoice.cidentity}
          </p>
          <p>
            <strong>{t("invoice.col_direccion")}:</strong>{" "}
            {selectedInvoice.address}
          </p>
          {/*<p><strong>{t('invoice.col_provincia')}:</strong> {selectedInvoice.cprovince}</p>*/}
          {/*<p><strong>{t('invoice.col_municipio')}:</strong> {selectedInvoice.cmunicipality}</p>*/}
          <p>
            <strong>{t("invoice.col_remitente")}:</strong>{" "}
            {selectedInvoice.sname}
          </p>
          <p>
            <strong>{t("invoice.col_shipperphone")}:</strong>{" "}
            {selectedInvoice.shipperphone}
          </p>

          {/* Mostrar productos de la factura */}
          <Table
            columns={productColumns}
            dataSource={selectedInvoice.products}
            pagination={false}
            rowKey="namegood"
          />
        </div>
      )}
    </Modal>
  );
};

export default InvoiceDetailsModal;
